import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { createHomepageBlock } from "../pages-utils/index/helper"

import "../pages-utils/index/style.scss"

const IndexPage = ({ data }) => {
  const {
    strapi: { posts = [] },
  } = data

  return (
    <Layout name="home">
      <SEO title="Home" />
      <h2>We are glad to see you!</h2>

      <div className="blocks-container">
        {posts.map(post => createHomepageBlock(post))}
      </div>
      {/*<pre>{JSON.stringify(posts, null, "\t")}</pre>*/}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    strapi {
      posts(where: { status: "published" }) {
        slug
        title
        created_at
        updated_at
        socialPhoto {
          image {
            url
          }
        }
        author {
          displayName
        }
        content {
          ... on Strapi_ComponentPostComponenetsPostImage {
            __typename
            title
            description
            image {
              url
              caption
            }
          }
          ... on Strapi_ComponentPostComponenetsText {
            __typename
            text
          }
        }
      }
    }
  }
`
